import { createStore } from 'vuex'

export default createStore({
  state: {
    leftNavStatus: false,
  },
  mutations: {
    SET_LEFT_NAV_STATUS(state, bool) {
      state.leftNavStatus = !!bool;
      // 把状态存到本地
      localStorage.setItem('leftNavStatus', bool);
    }
  },
  actions: {
  },
  modules: {
  }
})
