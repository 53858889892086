<template>
  <router-view />
</template>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 3px;
  width: 3px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  transition: color 0.2s ease;
}
</style>
