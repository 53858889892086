
import LeftNav from "@/layout/LeftNav.vue";
import AppMain from "@/layout/AppMain.vue";
import { useStore } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Layout",
  components: {
    LeftNav,
    AppMain,
  },
  setup() {
    const store = useStore();
    // 判断是否有缓存 leftNavStatus
    const hasLeftNavStatus = () => {
      const leftNavStatus = localStorage.getItem("leftNavStatus");
      if (leftNavStatus) {
        store.commit("SET_LEFT_NAV_STATUS", JSON.parse(leftNavStatus));
      }
    };
    hasLeftNavStatus();
    return {};
  },
});
