import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d0bdf1ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_nav = _resolveComponent("left-nav")!
  const _component_app_main = _resolveComponent("app-main")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_left_nav),
    _createVNode(_component_app_main)
  ]))
}