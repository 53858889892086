
import NavBar from "@/layout/NavBar.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppMain",
  components: {
    NavBar,
  },
});
