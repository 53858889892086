
import { defineComponent, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  name: "NavBar",
  setup() {
    // 获取路由meta.title中的标题
    const route = useRoute();
    const title = computed(() => route.meta.title || undefined);
    // 获取nav状态
    const store = useStore();
    const leftNavStatus = computed(() => store.state.leftNavStatus);
    // 修改nav状态
    const changeLeftNavStatus = () => {
      store.commit("SET_LEFT_NAV_STATUS", !leftNavStatus.value);
    };

    return {
      title,
      leftNavStatus,
      changeLeftNavStatus,
    };
  },
});
