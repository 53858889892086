import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
          title: 'ding云存储'
        }
      },
      {
        path: 'todo',
        name: 'Todo',
        component: () => import('@/views/todo/index.vue'),
        meta: {
          title: 'TodoList'
        }
      },
      {
        path: 'websocket',
        name: 'WebSocket',
        component: () => import('@/views/webSocket/index.vue'),
        meta: {
          title: 'WebSocket'
        }
      },
      {
        path: 'adventure',
        name: 'Adventure',
        component: () => import('@/views/adventure/index.vue'),
        meta: {
          title: 'Adventure'
        }
      },
      {
        path: 'Test',
        name: 'Test',
        component: () => import('@/views/test.vue'),
        meta: {
          title: 'Test'
        }
      },
    ]
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// vue3+ts路由守卫 游览器tab页标题根据路由元信息设置
router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string; // 设置浏览器 tab 标题
  next();
});

export default router
